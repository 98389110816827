.capa{
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(../../image/capa_home.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.blur{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.568);
    z-index: -2;
}

.home{
    color: var(--branco);
    
    
    margin-left: 50px;
    margin-right: 50px;
}

.nome_home{
    text-align: center;
    width: 500px;
}

.nome_home h1{
    font-size: 4em;
    font-family: 'padrao', sans-serif;
    letter-spacing: 5px;
}

.nome_home p{
    font-family: 'padrao', sans-serif;
    letter-spacing: 5px;
}

.frase{
    font-family: 'padrao', sans-serif;
    text-align: center;
    margin-top: 50px;
    font-size: 1.8em;
}

