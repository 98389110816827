.container_controle_de_jornada{
    background-image: url(../../image/capa_home.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: -2;

    width: 100%;
    
    
}

.blur_controle_de_jornada{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.568);
    z-index: -3;

    
}

.controle_jornada{
    display: flex;
    position: relative;
    background: linear-gradient(to top, var(--azulClaro), 80%, rgba(0, 0, 0, 0));

    z-index: -1;

    

}


.container_escrita{
    text-align: center;
    padding: 50px 30px 50px 30px;
    color: var(--branco);
    background-image: url(../../image/capa_jornada.png);
    background-position: center center;
    background-size: 700px;
    background-repeat: no-repeat;

    
}

.titulo_controle_de_jornada{
    font-family: 'padrao', sans-serif;
    letter-spacing: 4px;
    margin-bottom: 30px;

    opacity: 0;
}

.texto_controle_de_jornada{
    text-align: justify;
    font-size: 1.3em;
    font-family: 'escrita', serif;
    width: 99%;
    opacity: 0;
    
}

.lei{
    color: var(--cinza);
}

.lei:hover{
    color: var(--azulEscuro);
}


@media (max-width: 960px) {


    .container_escrita {
        background-size: 200%;
    }
}
