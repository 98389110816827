:root{
  --azulEscuro: #006CE3;
  --azulClaro: #0583F2;
  --preto: #000000;
  --branco: #ffffff;
  --vintage: #fdf9e1;
  --cinza: #c3cef7;
}

*{
  margin: 0;
  padding: 0;
  
}

html, body{
  overflow-x: hidden;
}

html{
  scroll-behavior: smooth;
}

body{
  width: 100vw;
  

  background-color: var(--branco);

  position: relative;
  z-index: -2;
    
}


@font-face {
  font-family: 'padrao';
  src: url('./fonts/coolvetica.otf');
}

@font-face {
  font-family: 'escrita';
  src: url('./fonts/Roboto-Regular.ttf');
}