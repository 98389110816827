.ani_escrita_ED{
    animation: ani_escrita_ED 1s ease-in-out forwards;
}

.ani_escrita_DE{
    animation: ani_escrita_DE 1s ease-in-out forwards;
}

.ani_BC{
    animation: ani_BC 1s ease-in-out forwards;
}

@keyframes ani_BC {
    0%{
        transform: translateY(200px);
        opacity: 0;
    }
    100%{
    
        opacity: 1;
    }
}

@keyframes ani_escrita_DE {
    0%{
        transform: translateX(200%);
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes ani_escrita_ED {
    0%{
        transform: translateX(-200%);
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}