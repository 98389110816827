.container_contato{
    background-color: var(--azulEscuro);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.contato{
    color: var(--branco);
    max-width: 800px;
    opacity: 0;
}

.titulo_contato{
    text-align: center;
    font-family: 'padrao', sans-serif;
    letter-spacing: 3px;
}

.desc_contato{
    font-family: 'escrita', serif;
    font-size: 1.3em;
    text-align: center;
    margin-top: 20px;
}

.container_numeros_contato{
    display: flex;
    font-family: 'escrita', serif;
    font-size: 1.5em;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 50px;
}

.link_whats{
    text-decoration: none;
    color: rgb(132, 206, 132);
    margin-top: 10px;
}

.link_whats:hover{
    color: rgb(95, 161, 95);
}

@media (max-width: 960px) {

    .container_numeros_contato{
        font-size: 1.2em;
    }

    .numeros_contato{
        width: 160px;
        padding: 10px;
    }


}