.nav_menu{
    height: 0%;
    width: 100vw;
    
    transition: 1s ease-in;
    
    }
    
    
    
    .img_logo{
        width: 180px;
        position: absolute;
        
    
        margin: 20px 20px;
        left: 0;
        
    }
    
    .burguer{
        width: 30px;
        height: 30px;
        position: absolute;
        display: none;
    
        margin: 20px 20px;
        right: 0;
        
        cursor: pointer;
        z-index: 10;
    }
    
    .fechado{
        opacity: 0;
        display: none;
        width: 30px;
        height: 30px;
        position: absolute;
        
        padding: 20px 20px;
        right: 0;
        z-index: 10;
        cursor: pointer;
    }
    
    .menu{
        display: flex;
        align-items: center;
        font-family: 'padrao', sans-serif;
        color: var(--branco);
        font-size: 1.1em;
        justify-content: center;
        position: absolute;
        background-color: aqua;
        padding-left: 50px;
        width: 100%;
        height: 100px;
        border-radius: 10px 10px 0px 0px;
        
        z-index: 9;
        
        background-color: rgba(0, 0, 0, 0.150);
        
    
        transition: 1s ease-in;
        overflow-x: hidden;
    }
    
    
    
    .lista_menu{
        position: relative;
        list-style: none;
        justify-content: center;
    
        opacity: 1;
        display: flex;
        
        transition: 1s ease-in;
    }
    
    .lista_menu a{
        text-decoration: none;
        color: white;
        letter-spacing: 5px;
    }
    
    .itens_menu{
        text-align: center;
        padding-top: 20px; 
        cursor: pointer;
        margin: 0px 20px;
        position: relative;
    }
    
    .itens_menu::after{
        content: '';
        width: 0%;
        height: 2px;
        background-color: var(--branco);
    
        position: absolute;
        left: 0%;
        bottom: -5px;
        
        transition: 0.5s ease-in-out;
    }
    
    .itens_menu:hover::after{
        width: 50%;
    }

    .giraCloseD{
        animation: giraCloseD 0.3s ease-in-out forwards;
    }
    
    .giraOpenD{
        animation: giraOpenD 0.3s ease-in-out forwards;
    }
    
    .giraCloseE{
        animation: giraCloseE 0.3s ease-in-out forwards;
    }
    
    .giraOpenE{
        animation: giraOpenE 0.3s ease-in-out forwards;
    }
    


    @media (max-width: 960px) {
        .nav_menu{
            position: fixed;
        }
    
        .img_logo {
            width: 100px;
    
        }
    
        .burguer {
            display: block;
        }
    
        .menu {
            flex-direction: column;
    
            height: 0%;
            padding: 0;
            border-radius: 20px;
    
            background-color: rgba(0, 0, 0, 0.541);
    
            backdrop-filter: blur(6px);
        }
    
        .lista_menu {
            display: none;
            opacity: 0;
        }
    
        .itens_menu {
            padding-top: 50px;
            margin: 0px;
        }
    }

    @keyframes giraCloseD{
        0%{
            transform: rotate(0deg);
            opacity: 1;
        }
        75%{
            opacity: 1;
        }
        100%{
            transform: rotate(180deg);
            opacity: 0;
            display: none;
        }
        
        
        }
        
        @keyframes giraOpenD{
            0%{
                transform: rotate(45deg);
                display: block;
            }
            75%{
                opacity: 1;
            }
            100%{
                transform: rotate(180deg);
                opacity: 1;
            }
        }
        
        @keyframes giraCloseE{
            0%{
                transform: rotate(0deg);
                opacity: 1;
            }
            75%{
                opacity: 1;
            }
            100%{
                transform: rotate(-180deg);
                opacity: 0;
                display: none;
            }
        }
        
        @keyframes giraOpenE{
            0%{
                transform: rotate(-45deg);
                display: block;
            }
            75%{
                opacity: 1;
            }
            100%{
                transform: rotate(-180deg);
                opacity: 1;
            }
        }