.container_IA {
    background-color: var(--azulEscuro);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 50px 0px;
}

.IA {
    display: flex;
}

.secao_IA {
    width: 500px;
    height: 350px;
    text-align: center;
    padding: 20px;
    background-color: var(--branco);
    margin: 30px;

    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 2px solid var(--preto);

    opacity: 0;
}




.titulo_IA {
    font-family: 'padrao', sans-serif;
    margin-top: 30px;
    margin-bottom: 30px;

    position: relative;
}

.desc_IA {
    font-family: 'escrita', serif;
    font-size: 0.5em;
    height: 30px;
    background-color: var(--cinza);
    padding: 5px;

    border-radius: 5px;

    position: absolute;
    top: -35px;
    left: calc(50% - 150px/2);

    opacity: 0;
    transition: 0.5s ease-in-out;

    clip-path: polygon(0% 0%,
            100% 0%,
            100% calc(100% - 10px),
            calc(50% + 10px) calc(100% - 10px),
            50% 100%,
            calc(50% - 10px) calc(100% - 10px),
            0% calc(100% - 10px));
}

.titulo_IA:hover .desc_IA {
    opacity: 1;
}

.escrita_IA {
    font-family: 'escrita', serif;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 30px;
}

.link_IA {
    color: var(--preto);
    font-family: 'escrita', serif;
    font-weight: bold;
    font-size: 1.3em;
    text-decoration: none;

    position: relative;
}

.link_IA:hover {
    color: gray;
}

.link_IA::after {
    content: '';
    width: 0%;
    height: 2px;
    background-color: gray;

    position: absolute;
    left: 0%;
    bottom: -5px;

    transition: 0.5s ease-in-out;
}

.link_IA:hover::after {
    width: 50%;
}


.QRcode_IA img {
    width: 350px;
}

@media (max-width: 960px) {

    .IA {
        flex-direction: column;
    }

    .secao_IA {
        width: 300px;
        height: 400px;
    }

    .QRcode_IA {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .QRcode_IA img {
        width: 200px;
    }
}