.loaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    position: relative;
}

.spinner {
    width: 100px;
    height: 100px;
    animation: rotate 2s linear infinite;
}

.circle {
    fill: none;
    stroke: #006CE3;
    stroke-width: 4;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -125;
    }
}

.loadingText {
    margin-top: 20px;
    font-size: 18px;
    color: #006CE3;
    font-weight: 500;
    letter-spacing: 1px;
}

/* Estilos para as pequenas linhas de vento */
.windLines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.smallLine {
    width: 50px; /* Comprimento menor */
    height: 2px;
    background-color: rgba(0, 108, 227, 0.3);
    position: absolute;
    left: -100%;
    animation: windSmall 2s linear infinite;
}

.smallLine:nth-child(1) { top: 10%; animation-duration: 3s; }
.smallLine:nth-child(2) { top: 20%; animation-duration: 2.8s; }
.smallLine:nth-child(3) { top: 30%; animation-duration: 3.2s; }
.smallLine:nth-child(4) { top: 40%; animation-duration: 2.6s; }
.smallLine:nth-child(5) { top: 50%; animation-duration: 3.5s; }
.smallLine:nth-child(6) { top: 60%; animation-duration: 2.7s; }
.smallLine:nth-child(7) { top: 70%; animation-duration: 3.1s; }
.smallLine:nth-child(8) { top: 80%; animation-duration: 3.4s; }
.smallLine:nth-child(9) { top: 90%; animation-duration: 2.5s; }
.smallLine:nth-child(10) { top: 15%; animation-duration: 3.0s; }
.smallLine:nth-child(11) { top: 35%; animation-duration: 3.2s; }
.smallLine:nth-child(12) { top: 55%; animation-duration: 2.9s; }

@keyframes windSmall {
    0% {
        left: -10%;
    }
    100% {
        left: 110%;
    }
}
