.body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;

    
    color: #333;
    line-height: 1.6;

    position: relative;
    z-index: -2;
}
