footer {
    background-color: black;
    color: #fff;
    font-size: 0.8em;
    text-align: center;
    padding: 5px;
    position: absolute;
    
    width: 100%;
}

.footer_link{
    text-decoration: none;
    color: var(--branco);
}

.footer_link:hover{
    cursor: pointer;
    text-decoration: underline;
}